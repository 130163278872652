import { graphql, useStaticQuery } from 'gatsby';
import Img, { FluidObject, GatsbyImageProps } from 'gatsby-image';
import React, { FC } from 'react';
import styled from 'styled-components';
import Appear from '../components/Appear';
import ActionDraw from '../components/atoms/ActionDraw';
import Body from '../components/atoms/Body';
import Box from '../components/atoms/Box';
import ContentWrapper from '../components/atoms/ContentWrapper';
import Stack from '../components/atoms/Stack';
import Title from '../components/atoms/Title';
import Content from '../components/Content';
import Layout from '../components/Layout';
import List, { Item } from '../components/List';
import SEO from '../components/Seo';
import { AboutQuery } from '../graphql';

export const query = graphql`
    query About {
        businessImage: file(relativePath: { eq: "business.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        happyImage: file(relativePath: { eq: "happy.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        usImage: file(relativePath: { eq: "working.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        amsImage: file(relativePath: { eq: "ams.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const About: FC = () => {
    const { amsImage, usImage } = useStaticQuery<AboutQuery>(query);
    // const { onOpen: onContactFormOpen } = useContactFormDispatch();
    return (
        <>
            <SEO
                title="Over ons"
                description="Eerlijkheid en transparantie via Verkoop Uw Woning voorkomen een kostbaar proces. Hierdoor is het mogelijk om u van een snel en eerlijk bod te voorzien."
            />
            <Layout whiteBackground>
                <SecondSection>
                    <ContentWrapper pt={[4, null, '10vh']}>
                        <Stack spacing={[0, null, '10rem']}>
                            <Content
                                rightWidth={[1, null, 0.4]}
                                left={
                                    <Box pr={[null, null, '5.2rem']} className="text" mt={[5, null, 0]}>
                                        <Title variant="large">Waarom via ons?</Title>
                                        <Body variant="huge">
                                            Uw woning via de klassieke en gebruikelijke manier verkopen is een kostbaar
                                            proces wat erg veel energie kost en waar bovendien soms maanden overheen
                                            gaat. In deze economisch onzekere tijden bieden wij u daarom een unieke
                                            mogelijkheid waarbij wij de waarde van uw woning snel kunnen bepalen en
                                            vervolgens zonder voorbehoud kunnen overnemen. Nadat wij uw aanvraag hebben
                                            ontvangen, sturen wij u binnen 24 uur een vrijblijvend bod op uw woning. Wij
                                            gaan discreet om met de informatie die u ons stuurt.
                                        </Body>
                                    </Box>
                                }
                                right={<ActionDraw width="100%" />}
                                flexDirection={['column-reverse', null, 'row']}
                            />
                        </Stack>
                    </ContentWrapper>
                    {/* <MainItems
                        flexDirection="column"
                        alignItems="center"
                        pt={[6, null, '10rem']}
                        pb={[6, null, '10rem']}
                        px={5}
                    >
                        <Box>
                            <Appear>
                                <Title elementType="h2" style={{ marginBottom: '2.4rem' }} variant="large">
                                    Onze kernwaarden
                                </Title>
                            </Appear>
                            <Stack {...({ spacing: 4 } as never)} display="block">
                                <Item as={Appear} variant="huge">
                                    Ontvang van ons een marktconform bod op uw pand
                                </Item>
                                <Item as={Appear} variant="huge">
                                    Uw pand kunt u via ons snel en discreet verkopen
                                </Item>
                                <Item as={Appear} variant="huge">
                                    U hoeft geen makelaarskosten te betalen.
                                </Item>
                                <Item as={Appear} variant="huge">
                                    Wij nemen af zonder voorbehoud van financiering.
                                </Item>
                                <Appear>
                                    <Button variant="transparant" mt={[4, null, 6]} onClick={onContactFormOpen}>
                                        Contact opnemen
                                    </Button>
                                </Appear>
                            </Stack>
                        </Box>
                    </MainItems> */}
                    <ContentWrapper mt={[0, null, '10rem']} mb={[null, null, '10rem']}>
                        <Stack spacing={4}>
                            <Content
                                flexDirection={['column', null, 'row']}
                                leftWidth={[null, null, 0.4]}
                                right={
                                    <Box pl={[null, null, '5.2rem']} className="text">
                                        <Title elementType="h2" variant="large">
                                            Wie zijn wij?
                                        </Title>
                                        <Body variant="huge">
                                            Wij zijn een jong en dynamisch team en door onze goede connecties binnen de
                                            vastgoedsector is het voor ons mogelijk om uw woning snel af te nemen. Wij
                                            hebben een ruime ervaring in de vastgoedsector. De tevredenheid van onze
                                            klanten staat op de eerste plaats en is iets waar wij veel waarde aan
                                            hechten.
                                        </Body>
                                    </Box>
                                }
                                left={<ContentImage fluid={usImage?.childImageSharp?.fluid as FluidObject} />}
                            />
                            <Content
                                flexDirection={['column', null, 'row-reverse']}
                                leftWidth={[null, null, 0.4]}
                                right={
                                    <Box pr={[null, null, '5.2rem']} className="text">
                                        <Title elementType="h2" variant="large">
                                            Waar zijn wij naar op zoek?
                                        </Title>
                                        <StyledBody variant="huge">
                                            Wij zijn vooral op zoek naar woningen in en rondom de steden in de Randstad.
                                            Wij richten ons vooral op de steden Amsterdam, Haarlem, Rotterdam, en Den
                                            Haag. Wij zijn erg flexibel in het afnemen van vastgoed:
                                        </StyledBody>
                                        <List style={{ marginTop: '1.6rem' }}>
                                            <Item as={Appear} variant="huge">
                                                Naast woningen bemiddelen wij onder andere ook winkelpanden of andere
                                                beleggingspanden.
                                            </Item>
                                            <Item as={Appear} variant="huge">
                                                Er wordt geen onderscheid gemaakt in de staat waarin uw woning verkeert.
                                            </Item>
                                            <Item as={Appear} variant="huge">
                                                Voor ons is het minder van belang of het object verhuurd is.
                                            </Item>
                                            <Item as={Appear} variant="huge">
                                                Wij bieden de mogelijkheid om het pand over te nemen ook als het pand
                                                pas later leeg wordt opgeleverd.
                                            </Item>
                                            <Item as={Appear} variant="huge">
                                                Tevens is het mogelijk een object of pand van u over te nemen en deze
                                                vervolgens voor onbepaalde tijd van ons te huren.
                                            </Item>
                                        </List>
                                    </Box>
                                }
                                left={<ContentImage fluid={amsImage?.childImageSharp?.fluid as FluidObject} />}
                            />
                        </Stack>
                    </ContentWrapper>
                </SecondSection>
            </Layout>
        </>
    );
};

const StyledBody = styled(Body)`
    color: ${({ theme }) => theme.colors.neutral[2]};
`;

// const MainItems = styled(Flex)`
//     color: white;
//     background: ${({ theme }) => theme.colors.primary[5]};
//     > div {
//         max-width: 40rem;
//     }
// `;

const SecondSection = styled.section`
    /* background: ${({ theme }) => theme.colors.primary[8]}; */
`;

export default About;

const MainImage = styled(Img)<GatsbyImageProps>`
    position: absolute;
    height: 100%;
`;

const ContentImage = styled(MainImage)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: 100vw;
        max-height: 40vh;
    }
`;
